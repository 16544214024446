// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function setSettingsId(evt, param) {
  adminFunc = param.adminFunc;
  $(
    '#menuCustomizingGlobal,#menuCustomizingIndustry,#menuCustomizingClient'
  ).removeClass('active');
  $(evt.currentTarget).addClass('active');
  localStorage.setItem(client + 'AdminFunc', adminFunc);
}
